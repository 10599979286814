import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Home from './home';
import CrashManagement from './crash-management';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      general {
        country_code
      }
    }
  `);

  if (data.general.country_code === 'au') {
    return (<Home />);
  }

  return (<CrashManagement />);
};
export default IndexPage;
